/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';

const typographyVariants = cva('text-foreground', {
  variants: {
    variant: {
      xs: 'text-xs',
      s: 'text-sm',
      span: 'text-base',
      p: 'text-base',
      h1: 'text-primary font-bold text-3xl lg:text-3xl',
      h2: 'text-fields font-bold text-2xl',
      h3: 'text-fields font-bold text-xl',
      h4: 'text-fields font-normal text-lg',
    },
  },
  defaultVariants: {
    variant: 'p',
  },
});

export interface TypographyProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  as?: string;
}

const Typography = React.forwardRef<HTMLElement, TypographyProps>(({ className, variant, as, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : as ?? (variant ? variant.toString() : undefined) ?? 'div';
  return <Comp className={cn(typographyVariants({ variant, className }))} ref={ref} {...props} />;
});

Typography.displayName = 'Typography';

export { Typography, typographyVariants };

type TypographyVariantType = NonNullable<VariantProps<typeof typographyVariants>['variant']>;

interface VariantPropsTypographyWithoutVariant extends Omit<VariantProps<typeof typographyVariants>, 'variant'> {
  asChild?: boolean;
}

export type { VariantPropsTypographyWithoutVariant, TypographyVariantType };
