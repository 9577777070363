import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

import { cn } from '../../lib/utils';

const CheckBox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>>(
  ({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'peer h-10 w-10 shrink-0 rounded-sm border border-primary ring-offset-background border-solid focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        className,
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <Check className='h-6 w-6' />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
);
CheckBox.displayName = CheckboxPrimitive.Root.displayName;

// eslint-disable-next-line import/prefer-default-export
export { CheckBox };
