/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { FaSpinner } from 'react-icons/fa';

import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'font-sans inline-flex items-center justify-center whitespace-nowrap rounded-md no-underline rounded-md focus-visible:outline-none',
  {
    variants: {
      variant: {
        default:
          'text-sm border-solid border-2 py-6 px-12 shadow-md bg-primary text-white border-primary hover:brightness-110 active:transition duration-300 active:ease-in-out font-bold',
        secondary:
          'text-sm border-solid border-2 py-6 px-12 shadow-md bg-white text-primary border-primary hover:brightness-95 active:transition active:ease-in-out font-bold',
        link: 'bg-transparent border-0 bg-none underline text-foreground hover:opacity-50',
      },
      size: {
        default: '',
        sm: 'py-3 px-8',
        lg: 'py-9 px-16',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  icon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, fullWidth = false, isLoading = false, icon = null, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const opacityStyle = !asChild && props.onClick === undefined && props.type !== 'submit' ? 'opacity-50 cursor-default' : '';
    const fullWidthStyle = fullWidth ? 'w-full' : '';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), opacityStyle, fullWidthStyle)}
        ref={ref}
        disabled={isLoading}
        tabIndex={props.type === 'submit' ? 0 : undefined}
        {...props}
      >
        {asChild ? (
          React.isValidElement(children) ? (
            React.cloneElement(children, undefined, children.props.children, !!icon && <div className='ml-2'>{icon}</div>)
          ) : null
        ) : (
          <>
            {children}
            {isLoading ? <FaSpinner className='animate-spin ml-2' aria-label='loading' /> : !!icon && <div className='ml-2'>{icon}</div>}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  primary: React.ReactElement<ButtonProps>;
  secondary?: React.ReactElement<ButtonProps>;
}

const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(({ className, ...props }, ref) => {
  const primary = React.cloneElement(
    props.primary,
    props.primary.props.variant && props.primary.props.variant !== 'default' ? { fullWidth: true } : { fullWidth: true, variant: 'default' },
  );
  const secondary = props.secondary
    ? React.cloneElement(
        props.secondary,
        props.secondary.props.variant && props.primary.props.variant !== 'secondary'
          ? { fullWidth: true }
          : { fullWidth: true, variant: 'secondary' },
      )
    : null;

  return (
    <div className={cn(className, 'flex flex-col md:flex-row mt-8')} ref={ref}>
      {props.secondary ? (
        <>
          <div className='basis-2/3 md:ml-8 mb-8 md:mb-0 md:order-2'>{primary}</div>
          <div className='basis-1/3 md:order-1'>{secondary}</div>
        </>
      ) : (
        primary
      )}
    </div>
  );
});
ButtonGroup.displayName = 'ButtonGroup';

export { Button, ButtonGroup, buttonVariants };
