import React from 'react';
import { StyledButton, Box } from '../design-system';
import ButtonSpinner from './ButtonSpinner';

const DefaultButton = ({ children, text, onClick, after, loading, ...props }) => {
  return (
    <StyledButton onClick={!loading ? onClick : null} {...props}>
      {!loading &&
        (after ? (
          <>
            {text}
            <Box ml={2}>{children}</Box>
          </>
        ) : (
          <>
            <Box mr={2}>{children}</Box>
            {text}
          </>
        ))}
      {loading && <ButtonSpinner />}
    </StyledButton>
  );
};

export default DefaultButton;
