import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const getPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;
  const number = parsePhoneNumberFromString(phoneNumber, 'GB');
  return number && number.isValid() ? number : false;
};

export const getMobileNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;
  const number = parsePhoneNumberFromString(phoneNumber, 'GB');
  return number && number.isValid() && number.getType() === 'MOBILE' ? number : false;
};

export const isValidEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
  const validInput = regex.test(email);
  return validInput;
};

const flattenForm = parsedJSON => {
  const { sections } = parsedJSON;
  const flattenedForm = {};
  let navigationOrder = 1;

  sections.sort((a, b) => (a.sectionNumber > b.sectionNumber ? 1 : -1));

  sections.forEach(section => {
    const { steps } = section;

    steps.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));

    steps.forEach(step => {
      flattenedForm[step.key] = {
        ...step,
        sectionKey: section.key,
        sectionTitle: section.title,
        navigationOptions: { title: section.title, inStack: step.backButtonMode === 'previous' },
        navigationOrder,
      };

      navigationOrder += 1;
    });
  });

  return flattenedForm;
};

const formatCurrency = (amount, denomination) => {
  const sign = amount < 0 ? '-' : '';
  let _amount = null;

  switch (denomination) {
    case 'pounds':
      _amount = amount;
      break;
    case 'pence':
    default:
      _amount = amount / 100;
      break;
  }

  const formatted = _amount
    .toString()
    .replace('-', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${sign}£${formatted}`;
};

export const agreementProcessEnum = Object.freeze({ BORROW: 'borrow', JOIN: 'join', REGISTRATION: 'registration' });

export const filterUserAgreements = (userAgreements: unknown, agreementEnum: unknown) =>
  Object.values(userAgreements)
    .filter(agreement => agreement.processKeys.includes(agreementEnum))
    .map(({ title, value, key }) => ({ title, value, key }));

export const parseError = error => {
  // GraphQL
  // --------
  // error.graphQLErrors[].message
  // error.graphQLErrors[].extensions.code
  // error.graphQLErrors[].extensions.exception.key

  // AWS
  // -------
  // error.code

  const { graphQLErrors } = error;

  if (graphQLErrors?.length) {
    const { extensions, message } = graphQLErrors[0];
    const key = extensions?.exception?.key;

    return { message, key, code: extensions?.code };
  }

  return { message: error.message, code: error.code };
};

export default { getMobileNumber, isValidEmail, flattenForm, formatCurrency, agreementProcessEnum, filterUserAgreements };
