import { setContext } from '@apollo/client/link/context';
import { ApolloLink, HttpLink, ApolloClient } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { Auth } from '@aws-amplify/auth';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_CORE_API_URL || 'https://uat-api.glasgowcu.com/api',
  credentials: 'include',
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  } catch (error) {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const versionLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    platform: 'WEB',
  },
}));

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line
    for (const error of graphQLErrors) {
      switch (error.extensions.code) {
        case 'UNAUTHENTICATED':
          Auth.signOut();
          if (!error.path || !error.path.includes('preAuthCheck')) window.location.reload();
          return;
        default:
      }
    }
  }
});

const link = authLink.concat(ApolloLink.from([versionLink, errorLink, httpLink]));
const cache = new InMemoryCache();
const client = new ApolloClient({
  link,
  cache,
});

export default client;
