/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Typography, type VariantPropsTypographyWithoutVariant, type TypographyVariantType } from './Typography';
import { FilterUnionType } from '../../lib/types/FilterUnionType';

type AllowedVariants = FilterUnionType<TypographyVariantType, 'h1' | 'h2' | 'h3' | 'h4'>;
type HTMLTypographyElement = HTMLHeadingElement;

interface HeadingProps extends React.HTMLAttributes<HTMLTypographyElement>, VariantPropsTypographyWithoutVariant {
  variant: AllowedVariants;
  as?: AllowedVariants;
}

const Heading2 = React.forwardRef<HTMLTypographyElement, HeadingProps>(({ variant, as, ...props }, ref) => (
  <Typography ref={ref} variant={variant} as={as} {...props} />
));

Heading2.displayName = 'Heading2';

export { Heading2 };
