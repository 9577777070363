import React, { createContext, useContext } from 'react';
import { useAuth } from './authContext';

const UserContext = createContext(null);

const UserProvider = props => {
  const {
    data: { user },
  } = useAuth();
  return <UserContext.Provider value={user} {...props} />;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
