import * as React from 'react';

import { cn } from '../../lib/utils';
import { Text } from './Text';

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  steps: number;
  currentStep: number;
  showLabel?: boolean;
  progressColour?: string;
}

const ProgressBar = ({ className, steps, currentStep, showLabel, progressColour, ...props }: ProgressBarProps) => {
  const progress = steps > 0 && currentStep > 0 ? (currentStep / steps) * 100 : 0;

  const progressBarColor = progressColour || 'bg-primary';

  return (
    <div className={cn('fle flex-col', className)} {...props}>
      {showLabel && (
        <div>
          <Text className='font-bold' as='span'>
            {currentStep}
          </Text>
          <Text as='span'> of {steps}</Text>
        </div>
      )}
      <div className='w-full h-4 relative'>
        <div className={cn(`w-full rounded-full h-4`, progressBarColor, 'opacity-25')} />
        <div className={cn(`bg-primary h-4 rounded-full absolute top-0 left-0`, progressBarColor)} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};
ProgressBar.displayName = 'ProgressBar';

// eslint-disable-next-line import/prefer-default-export
export { ProgressBar };
