import React, { useState } from 'react';
import styled from '@emotion/styled';
import TextBox from './TextBox';
import { Box, theme, TextStyled, StyledLabel } from '../design-system';
import Icon from './Icon';

const CardStyled = styled(Box)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${props => (props.active ? `border: 4px solid ${theme.colors.primary}` : `border: 4px solid transparent`)};
  a {
    justify-content: flex-start;
    padding-left: 0;
    font-weight: 700;
  }
  svg {
    position: absolute;
    bottom: -7px;
    right: -36px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Card = ({
  background,
  width,
  color,
  iconBackground,
  buttonText,
  description,
  iconSize,
  as,
  heading,
  selected,
  setSelected,
  i,
  item,
  setProductSelection,
  setError,
  addContraLoanAccount,
  preSelected,
}) => {
  const [checked, setChecked] = useState(preSelected);

  const handleCheckbox = () => {
    setChecked(!checked);
    addContraLoanAccount(item.balanceId, { isSelected: !checked, ...item });
  };

  return (
    <CardStyled
      p='20px'
      borderRadius={8}
      width={width}
      bg={background}
      selected={selected}
      onClick={() => {
        setError('');
        setSelected(i);
        if (setProductSelection) setProductSelection(item);
        if (addContraLoanAccount) handleCheckbox();
      }}
      active={addContraLoanAccount ? checked : selected === i}
    >
      <Icon icon='calculator' color={theme.colors.primary} bg={iconBackground} size={iconSize} />
      <TextBox heading={heading} as={as} color={color} width='70%' headingSize={[3, 4, null, null]} mb='20px'>
        <StyledLabel mt='0' fontSize={[1, 2]} lineHeight={['19px', '23px']}>
          {description}
        </StyledLabel>
      </TextBox>
      <TextStyled
        as='p'
        color={(addContraLoanAccount ? checked : selected === i) ? theme.colors.primary : theme.colors.gray}
        fontWeight='bold'
        mt='auto'
      >
        {buttonText || ((addContraLoanAccount ? checked : selected === i) ? 'Loan selected' : 'Select this loan')}
      </TextStyled>
    </CardStyled>
  );
};

export default Card;
