import * as React from 'react';
import { Loader2 } from 'lucide-react';

import { cn } from '../../lib/utils';

export type LoadingSpinnerProps = React.HtmlHTMLAttributes<HTMLElement>;

const LoadingSpinner = ({ className, ...props }: LoadingSpinnerProps) => (
  <div className={cn('flex flex-1 justify-center items-center', className)} {...props}>
    <Loader2 className='animate-spin h-20 w-20 text-primary' />
  </div>
);

LoadingSpinner.displayName = 'LoadingSpinner';

export { LoadingSpinner };
