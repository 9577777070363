/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { cva } from 'class-variance-authority';
import { useToast } from './use-toast';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './Toast';
import { cn } from '../../../lib/utils';

type ToastVariant = 'default' | 'destructive' | 'success';

const titleVariants = cva('', {
  variants: {
    variant: {
      default: '',
      destructive: 'text-destructive',
      success: 'text-success',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const Toaster = () => {
  const { toasts } = useToast();

  const toastIcon = (variant: ToastVariant) => {
    const base = ' flex flex-1 justify-center items-center mr-5';

    switch (variant) {
      case 'destructive':
        return (
          <div className={cn(base, 'text-destructive border-destructive')}>
            <FontAwesomeIcon icon='xmark' size='1x' className='rounded-full border-2 border-solid w-8 h-8 p-2' />
          </div>
        );
      case 'success':
        return (
          <div className={cn(base, 'text-success border-success')}>
            <FontAwesomeIcon icon='check' size='1x' className='rounded-full border-2 border-solid w-8 h-8 p-2' />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, variant, ...props }) => (
        <Toast key={id} {...props}>
          <div className='grid gap-1'>
            {title && <ToastTitle className={cn(titleVariants({ variant }), 'text-fields')}>{title}</ToastTitle>}
            <div className='flex flex-row items-center'>
              {icon && toastIcon(variant)}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
          </div>
          {action}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
};
