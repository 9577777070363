import React from 'react';
import Select, { components } from 'react-select';
import styled from '@emotion/styled';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Heading, theme, Flex } from '../design-system';

const StyledSelect = styled(Select)`
  &.react-select--is-disabled {
    border-bottom: 1px solid #dcdcdc;
  }
  .ps-scrollbar-y-rail,
  .ps__rail-y {
    opacity: 0.6;
  }
`;

export const HiddenInput = styled.input`
  width: 100%;
  border: 0px;
  position: relative;
  height: 0px !important;
  box-sizing: border-box;
  border-top: 1px solid #dcdcdc;
  box-shadow: none;
  color: transparent;
  background: transparent;
  &:focus,
  &:active {
    outline: none;
    border-top: 1px solid red;
  }
`;

const customStyles = {
  control: () => ({
    border: 0,
    height: '40px',
    lineHeight: '32px',
    width: '100%',
    display: 'flex',
    color: '#391354',
    fontSize: '16px',
  }),
  menuList: () => ({
    fontSize: '25px',
    maxHeight: '200px',
    overflow: 'scroll',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#000',
  }),
};

const renderScrollbar = ({ children }) => {
  return (
    <div style={{ height: '200px', fontSize: '16px' }}>
      <PerfectScrollbar>{children}</PerfectScrollbar>
    </div>
  );
};

const renderFullMenu = ({ children }) => {
  return <div style={{ height: 'auto', fontSize: '16px' }}>{children}</div>;
};

const DropdownIndicator = props => {
  const { selectProps } = props;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={selectProps.menuIsOpen ? 'caret-up' : 'caret-down'} color={theme.colors.primary} />
      </components.DropdownIndicator>
    )
  );
};

const SelectBox = ({ title, titleColor, options, onChange, placeholder, isDisabled, value, required, scrollable = false }) => {
  return (
    <Flex flexDirection='column' justifyContent='space-between' flex={1}>
      {title && (
        <Heading as='h4' color={titleColor}>
          {title}
        </Heading>
      )}
      <StyledSelect
        options={options}
        className='react-select-container'
        classNamePrefix='react-select'
        components={{
          MenuList: scrollable ? renderScrollbar : renderFullMenu,
          DropdownIndicator: isDisabled ? null : DropdownIndicator,
        }}
        onChange={onChange}
        styles={customStyles}
        placeholder={placeholder || 'Select'}
        menuPlacement='bottom'
        isDisabled={isDisabled}
        value={value}
        required={required}
      />
      {!isDisabled && (
        <HiddenInput onChange={() => null} tabIndex={-1} autoComplete='off' value={value ? value || value.value : ''} required={required} />
      )}
    </Flex>
  );
};

export default SelectBox;
