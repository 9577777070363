// eslint-disable-next-line import/prefer-default-export
import { ReactNode } from 'react';

interface EmphasiseContent {
  content: string;
  link?: string;
}

type Chunk = EmphasiseContent & {
  emphasise: boolean;
};

export interface Content {
  content: string;
  emphasise?: EmphasiseContent[];
}

export const emphasiseContent = (
  content: Content,
  onChunk: (chunk: string, index: number, emphasise: boolean, link?: string) => JSX.Element,
): ReactNode => {
  if (!content) {
    return null;
  }

  if (!content.emphasise) {
    return onChunk(content.content, 0, false, null);
  }

  const emphasisedChunks: Chunk[] = [];

  let currentChunkStart = 0;

  content.emphasise.forEach(emphasised => {
    const index = content.content.indexOf(emphasised.content, currentChunkStart);

    if (index !== -1) {
      const precedingChunk = content.content.substring(currentChunkStart, index);
      emphasisedChunks.push({ content: precedingChunk, link: undefined, emphasise: false });

      const emphasisedChunk = content.content.substring(index, index + emphasised.content.length);
      emphasisedChunks.push({ content: emphasisedChunk, link: emphasised.link, emphasise: true });

      currentChunkStart = index + emphasised.content.length;
    }
  });

  const finalChunk = content.content.substring(currentChunkStart);
  emphasisedChunks.push({ content: finalChunk, emphasise: false });

  return emphasisedChunks.map((value, index) => onChunk(value.content, index, value.emphasise, value.link));
};
