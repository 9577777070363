import styled from '@emotion/styled';

import { Link } from '@reach/router';
import Select from 'react-select';

// eslint-disable-next-line no-unused-vars
import { lighten } from 'polished';

import {
  space,
  color,
  layout,
  grid,
  background,
  alignItems,
  border,
  typography,
  flexbox,
  flexWrap,
  flexDirection,
  justifyContent,
  flex,
  order,
  flexBasis,
  flexGrow,
  alignSelf,
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas,
  gridArea,
  gridColumn,
  position,
  shadow,
  boxShadow,
  system,
} from 'styled-system';
import { theme } from './theme';

export const StyledSelect = styled(Select)`
  /* .select__menu-list::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  .select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
`;

export const UnorderedList = styled.ul`
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-gap: 10px;
  li {
    position: relative;
    padding-left: 15px;
    font-size: ${props => props.fontSize};
    display: flex;
    align-items: center;
    &:after {
      content: ' ';
      display: block;
      height: 4px;
      width: 4px;
      background: ${props => props.after};
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  ${space}
  ${color}
  ${typography}
  ${gridTemplateColumns}
`;

export const Input = styled.input`
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${alignSelf}
  ${border}
  ${flex}
  ${flexBasis}
`;

export const StyledCheckbox = styled.label`
  ${space}
  ${gridColumn}
  ${flexDirection}
  ${justifyContent}
  display: inline-flex;
  cursor: pointer;
  position: relative;
  input {
    min-height: 25px;
    min-width: 25px;
    max-height: 25px;
    max-width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #e8e8e8;
    outline: none;
    transition-duration: 0.3s;
    margin: 0;
    cursor: pointer;
    &:checked {
      border: 1px solid;
      border-color: ${theme.colors.primary};
      & + span {
        &:before {
          content: '\\2713';
          display: block;
          text-align: center;
          color: ${theme.colors.primary};
          position: absolute;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0px;
          left: 0px;
          line-height: 25px;
        }
      }
    }
    &:active {
      border: 1px solid ${theme.colors.primary};
    }
  }
  span {
    margin-left: 10px;
  }
`;

export const StyledLabel = styled.label`
  ${space}
  ${color}
  ${typography}
  ${flex}
  ${gridArea}
`;

export const Box = styled.div`
  box-sizing: border-box;
  min-width: 0;
  ${system({
    translate: {
      property: 'transform',
      transform: value => `translate(${value})`,
    },
  })}
  ${border}
  ${background}
  ${space}
  ${color}
  ${layout}
  ${alignItems}
  ${grid}
  ${gridColumn}
  ${flex}
  ${justifyContent}
  ${position}
  ${flexbox}
  ${boxShadow}
  ${typography}
`;

export const Flex = styled(Box)(
  {
    display: 'flex',
  },
  flexGrow,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  gridArea,
  gridColumn,
  background,
  order,
);

Flex.displayName = 'Flex';

export const Grid = styled(Box)(
  {
    display: 'grid',
  },
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas,
  flex,
  background,
);

Flex.displayName = 'Grid';

export const Heading = styled.h1(
  {
    margin: 0,
  },
  color,
  space,
  typography,
  border,
  gridArea,
);

export const TextStyled = styled.p(
  {
    color: 'grey',
  },
  space,
  color,
  typography,
  layout,
  flex,
  order,
  alignSelf,
  position,
  gridColumn,
);

export const LinkStyled = styled.a(
  {
    color: 'grey',
  },
  space,
  color,
  typography,
  layout,
  background,
);

export const ReachStyled = styled(Link, {
  shouldForwardProp: prop => prop !== 'noDec',
})`
  color: grey;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${background}
  ${props => props.noDec && 'text-decoration:none'};
`;

export const StyledButton = styled.button`
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${props => !props.noBorder && `border: 2px solid ${theme.colors.primary};`};

  ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.delete
      ? {
          background: theme.colors.deleteRed,
          color: 'white',
          border: 'none',
          '&:hover': {
            background: '#a30f0f',
          },
        }
      : props.primary && !props.secondary
        ? {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            border: 'none',
            '&:hover': {
              background: 'rgba(0, 104, 146, 1)',
            },
          }
        : {
            background: theme.colors.white,
            color: theme.colors.primary,
            border: props.noBorder ? `none` : `2px solid ${theme.colors.primary}`,
            '&:hover': {
              background: props.noBorder ? 'transparent' : 'rgba(12, 73, 106, 0.1)',
            },
          }}

  opacity: ${props => (props.disabled ? '0.5' : '1')};
  text-align: center;

  padding: 16px 30px;
  line-height: 18px;
  font: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;s
  ${props => !props.noBorder && `box-shadow: 3px 0px 3.84px rgba(44, 149, 181, 0.25);`};
  transition: background 0.3s ease-in-out;
  ${props =>
    props.bg === 'transparent' &&
    `
    transition: opacity 0.3s ease-in-out;
  `}
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${flex}
  ${flexDirection}
  ${justifyContent}
  ${alignSelf}
  ${position}
  ${boxShadow}
  ${background}
  ${props =>
    props.as === 'a' &&
    !props.primary &&
    `    
    background:transparent;                                         
  `};
  &:hover {
    cursor: pointer;
    ${props => props.bg === 'transparent' && `opacity: 0.5`};
  }
`;

export const DynamicComponet = styled.div(space, color, layout, grid, alignItems);

export const Divider = styled.div`
  ${space}
  ${color}
  ${layout}
  ${theme}
`;

export const TextBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${space}
  ${color}
  ${layout}
  ${border}
  ${typography}
  ${gridArea}
`;

export const TitleWithText = styled.div`
  .title {
    margin: 0;
    font-size: 25px;
    color: ${theme.colors.secondary};
  }
  p {
    color: ${theme.colors.gray};
    font-size: 14px;
  }
  ${props => props.border && `border-bottom: 1px solid #eee`}
`;

export const StyledForm = styled.form`
  ${space}
  ${position}
  ${layout}
  ${flexDirection}
  label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      color: ${theme.colors.gray};
      font-size: 12px;
    }
    input:not([type='checkbox']) {
      ${color}
      border: 0px;
      height: 40px;
      border-bottom: 1px solid #dcdcdc;
      font-size: 16px;
      line-height: 32px;
      color: ${props => props.color};
      background: transparent;
      &:focus {
        outline: none;
      }
      &:focus {
        &[value=''] {
          &:invalid {
            border-color: red;
          }
        }
      }
    }
  }
`;

export const StyledImg = styled.img`
  ${space}
  ${layout}
  ${position}
  ${shadow}
  ${border}
  ${system({
    translate: {
      property: 'transform',
      transform: value => `translate(${value})`,
    },
  })}
`;

export const StyledLink = styled.a`
  ${space}
  ${layout}
  ${position}
  ${shadow}
  ${border}
  ${color}
  ${typography}
  ${justifyContent}
  ${alignItems}
  ${props => props.noDec && 'text-decoration:none'};
  ${props => props.cursor && 'cursor: pointer'};
  transition: all 0.3s ease-in-out;
  &:hover {
    ${props => (props.bg ? `background: ${lighten('0.1', props.bg)}` : `opacity: 0.5`)};
  }
`;
