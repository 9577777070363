import * as React from 'react';

import { cn } from '../../lib/utils';
import { Label } from './Label';

export interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  label?: string;
  helperText?: string;
  textareaStyle?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, id, label, error, helperText, textareaStyle, ...props }, ref) => {
  const base =
    'flex min-h-[80px] w-full rounded-md border border-fields bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50';

  const errorStyle = error ? 'border-destructive focus:border-destructive' : 'focus:border-primary';
  const cnClassName = cn(base, className, textareaStyle, errorStyle);

  return (
    <div className={className}>
      {label && (
        <Label className={cn('block mb-4 font-bold text-fields', error && 'text-destructive')} htmlFor={id}>
          {label}
        </Label>
      )}
      <textarea className={cnClassName} ref={ref} {...props} />
      {helperText && <p className={cn('text-sm text-fields')}>{helperText}</p>}
      {error && <p className={cn('text-sm font-medium text-destructive')}>{error}</p>}
    </div>
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
