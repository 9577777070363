/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Content } from '../lib/strings';
import { Text } from './ui/Text';

interface Props {
  items: Content[];
  type?: 'tick' | 'circle';
}

const CheckList = (props: Props) => {
  const { items, type = 'tick' } = props;

  return (
    <div className='space-y-8'>
      {items.map((i, index) => {
        const key = `${index}_${i}`;
        return (
          <div key={key} className='items-center flex flex-row m-0 p-0'>
            {type === 'circle' ? (
              <FontAwesomeIcon icon={faCircle} className='w-3 h-3 text-primary' />
            ) : (
              <FontAwesomeIcon icon={faCheck} className='w-6 h-6 text-primary' />
            )}
            <Text className='m-0 p-0 ml-4'>{i.content}</Text>
          </div>
        );
      })}
    </div>
  );
};

CheckList.displayName = 'CheckList';

export { CheckList };
