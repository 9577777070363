import React, { lazy, useEffect, Suspense } from 'react';
import { useQuery } from '@apollo/client';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Toaster } from '@soar/component-library';
import { Global, css, ThemeProvider } from '@emotion/react';
import { gcuTheme } from './components/Themes';
import { useUser } from './context/userContext';
import { useAuth } from './context/authContext';
import Throbber from './library/Throbber';
import './styles/index.css';
import {
  Get_Maintenance_Mode_Data_QueryDocument,
  Get_Maintenance_Mode_Data_QueryQuery,
  Get_Maintenance_Mode_Data_QueryQueryVariables,
} from './graphql/types';

config.autoAddCss = false;

const loadAuthenticatedApp = () => import('./authenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./unauthenticatedApp'));
const MaintenanceView = lazy(() => import('./screens/MaintenanceScreen'));

const App = () => {
  const user = useUser();
  const { errorLogout } = useAuth();

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  const { loading, data } = useQuery<Get_Maintenance_Mode_Data_QueryQuery, Get_Maintenance_Mode_Data_QueryQueryVariables>(
    Get_Maintenance_Mode_Data_QueryDocument,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );
  if (loading) {
    return <Throbber />;
  }

  let screenToLoad = user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
  if (data?.getMaintenanceModeData?.maintenanceMode) {
    if (user) {
      errorLogout();
    }
    screenToLoad = (
      <MaintenanceView
        title={data.getMaintenanceModeData.maintenanceMessage?.messageTitleWeb}
        body={data.getMaintenanceModeData.maintenanceMessage?.messageBodyWeb}
      />
    );
  }
  return (
    <ThemeProvider theme={gcuTheme}>
      <Suspense fallback={<Throbber />}>
        <main>
          <Global
            styles={css`
              * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
              }
              body {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                line-height: 1.2;
                overflow-x: hidden;
              }
              #root,
              main {
                display: flex;
                flex-direction: column;
                flex: 1;
              }
            `}
          />
          {screenToLoad}
        </main>
      </Suspense>
      <Toaster />
    </ThemeProvider>
  );
};

export default App;
