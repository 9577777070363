/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { Heading2 } from './ui/Heading2';
import { Text } from './ui/Text';
import { Button } from './ui/Button';
import { Content, emphasiseContent } from '../lib/strings';

interface Props {
  title?: string;
  heading?: string;
  subHeading?: string | Content[];
  topIcon?: 'error' | 'success';
  action?: {
    to: string;
    title: string;
  };
  onNavigate?: () => void;
}

const Feedback = (props: Props) => {
  const { title, heading, subHeading, topIcon, action, onNavigate } = props;

  return (
    <div className='flex justify-center'>
      <div className='items-center flex flex-col mt-20 w-full md:w-3/4 space-y-12 bg-background rounded-md'>
        {topIcon ? (
          <FontAwesomeIcon icon={faExclamation} size='3x' className='text-destructive rounded-full border-solid w-10 h-10 p-5' />
        ) : (
          <FontAwesomeIcon icon='check' size='3x' className='text-success rounded-full border-success border-solid w-20 h-20 p-5' />
        )}
        <Heading2 variant='h2' as='h3' className='text-fields text-center font-bold'>
          {title}
        </Heading2>
        <Heading2 variant='h4' className='text-foreground text-center'>
          {heading}
        </Heading2>
        {subHeading &&
          (typeof subHeading === 'string' ? (
            <Text className='text-foreground text-center'>{subHeading}</Text>
          ) : (
            <>
              {subHeading.map(i => (
                <Text className='text-foreground text-center' key={i.content}>
                  {emphasiseContent(i, (chunk, index, emphasise, link) => {
                    if (link) {
                      return (
                        <Button asChild variant='link'>
                          <Link to={link} onClick={onNavigate}>
                            {chunk}
                          </Link>
                        </Button>
                      );
                    }

                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    return <>{chunk}</>;
                  })}
                </Text>
              ))}
            </>
          ))}
        <div className='flex flex-1 justify-end items-end'>
          {action && (
            <Button asChild onClick={onNavigate}>
              <Link to={action.to}>{action.title}</Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Feedback.displayName = 'Feedback';

export { Feedback };
