import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './authContext';
import { UserProvider } from './userContext';

import client from '../utils/apiClient';

const AppProviders = ({ children }) => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  </ApolloProvider>
);

export default AppProviders;
