export const soarTheme = {
  primaryColor: '#391354',
  secondaryColor: 'rgb(255,200,200)',
  primaryButtonColor: '#2c95b5',
  secondaryButtonColor: '#32075a',
};
export const gcuTheme = {
  primaryColor: '#2C95B5',
  secondaryColour: '#818A99',
  primaryButtonColor: '#2C95B5',
  secondaryButtonColor: '#FFFFFF',
};
export const primaryTheme = {
  primaryColour: 'primary',
  secondaryColour: 'rgb(200,255,200)',
};
