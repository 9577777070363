/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Feedback } from './Feedback';
import { Button } from './ui/Button';

interface Props {
  subHeading?: string;
  retry?: {
    action: () => Promise<void>;
  };
}

const ErrorState = (props: Props) => {
  const { subHeading, retry } = props;

  const onPress = async () => {
    if (retry) {
      retry.action();
    }
  };

  return (
    <>
      <Feedback heading='Something went wrong' subHeading={subHeading} topIcon='error' />
      {retry && (
        <div className='flex justify-center'>
          <Button title='Retry' onClick={onPress}>
            Retry
          </Button>
        </div>
      )}
    </>
  );
};

ErrorState.displayName = 'ErrorState';

export { ErrorState };
