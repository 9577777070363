import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from './Text';

import { cn } from '../../lib/utils';

export interface Props extends React.InputHTMLAttributes<HTMLDivElement> {
  title: string;
  content: string;
  type: 'dismiss' | 'action';
  rightView?: React.ReactNode;
  onPress: () => void;
}

const Message = ({ title, content, type, onPress, rightView, className, ...props }: Props) => (
  <div className={cn('wrapper mb-10', className)} {...props}>
    <div className='bg-fields p-5 rounded-md'>
      <div className='flex flex-row justify-between'>
        <div className='mb-2 flex-1'>
          <Text className='font-bold text-white mt-0'>{title}</Text>
          <Text className='text-white mt-0'>{content}</Text>
        </div>
        {rightView && <div className='flex sm:items-center'>{rightView}</div>}
        <div className='ml-5'>
          {type === 'action' ? (
            <FontAwesomeIcon icon='close' className='size-12 text-white cursor-grab' onClick={onPress} />
          ) : (
            <FontAwesomeIcon icon='angle-double-right' className='size-12 text-white cursor-grab' onClick={onPress} />
          )}
        </div>
      </div>
    </div>
  </div>
);

Message.displayName = 'Message';

// eslint-disable-next-line import/prefer-default-export
export { Message };
