import React from 'react';
import styled from '@emotion/styled';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { theme, TextStyled } from '../design-system';

const Styles = styled.div`
  .rangeslider {
    background: #dcdcdc;
    box-shadow: none;
    &.rangeslider-horizontal {
      height: 6px;
    }
    .rangeslider__fill {
      box-shadow: none;
      background-color: ${theme.colors.primary};
    }
    .rangeslider__handle {
      background: ${theme.colors.secondary};
      height: 20px;
      width: 20px;
      :after {
        content: none;
      }
    }
  }
`;

const RangeSlider = ({ max, min, value, onChange, label, color, step, treatOneAsSpecialCase }) => {
  // By default the slider will add/subtract step like you would expect. e.g for a step of 6: 0 -> 6 -> 12...
  // but this causes weird behaviour if minValue is set to 1
  // In this case we might want to the slider to have the following values: 1 -> 6 -> 12...
  const oneIsSpecialCase = treatOneAsSpecialCase && min === 1;

  return (
    <Styles color={color}>
      <TextStyled as='span' htmlFor='' fontSize={[2]} color={theme.colors.gray}>
        {label}
      </TextStyled>
      <Slider
        value={value}
        min={oneIsSpecialCase ? 0 : min}
        max={max}
        onChange={changedValue => {
          const newValue = oneIsSpecialCase && changedValue === 0 ? min : changedValue;

          onChange(newValue);
        }}
        handleColour
        step={step}
      />
    </Styles>
  );
};

export default RangeSlider;
