import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactCodeInput from 'react-code-input';
import { cn } from '../../lib/utils';
import { Label } from './Label';

export interface CodeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  helperText?: string;
}

const CodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(({ className, id, label, helperText, error, ...props }, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onToggle = () => {
    setPasswordVisible(s => !s);
  };

  return (
    <div className={className}>
      {label && (
        <Label className={cn('block mb-4 font-bold text-fields', error && 'text-destructive')} htmlFor={id}>
          {label}
        </Label>
      )}
      <div className={cn('flex flex-row justify-between w-full relative')}>
        <ReactCodeInput
          ref={ref}
          className={`grid grid-cols-6 gap-x-5 w-11/12 ${cn(className, error ? 'react-code-input-error' : '')}`}
          fields={6}
          type={passwordVisible ? 'number' : 'password'}
          name='pin'
          id='pin'
          pattern='[0-9]*'
          inputMode='numeric'
          filterChars={[...'0123456789']}
          filterCharsIsBlacklist={false}
          required
          autoFocus={false}
          {...props}
        />
        <div className='flex items-center justify-center absolute right-4 h-full'>
          <FontAwesomeIcon icon='eye' onClick={onToggle} className='text-fields text-sm cursor-pointer' />
        </div>
      </div>
      {helperText && <p className={cn('text-sm text-fields')}>{helperText}</p>}
      {error && (
        <p className={cn('text-sm font-medium text-destructive')} {...props}>
          {error}
        </p>
      )}
    </div>
  );
});

CodeInput.displayName = 'CodeInput';

export { CodeInput };
