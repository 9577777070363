/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useLayoutEffect, useContext } from 'react';
import { useAsync } from 'react-async';
import bootstrapAppData from '../utils/bootstrap';
import * as authClient from '../utils/authClient';
import Throbber from '../library/Throbber';

const AuthContext = createContext(null);

const AuthProvider = props => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });
  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <Throbber />;
    }

    if (isRejected) {
      return (
        <div>
          <p>There is a problem, try refreshing the app</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const login = form => authClient.login(form).then(reload);
  const register = form => authClient.register(form);
  const validateDetails = form => authClient.validateDetails(form);
  const acceptTerms = form => authClient.acceptTerms(form);
  const validateSecurityQuestions = form => authClient.validateSecurityQuestions(form);
  const updateDetails = form => authClient.updateDetails(form);
  const resetCredentials = form => authClient.resetCredentials(form);
  const resetCredentialsSubmit = form => authClient.resetCredentialsSubmit(form);
  const completeRegistration = form => authClient.completeRegistration(form);
  const logout = () => authClient.logout().then(reload);
  const errorLogout = () => authClient.logout();
  return (
    <AuthContext.Provider
      value={{
        data,
        login,
        logout,
        register,
        completeRegistration,
        validateDetails,
        resetCredentials,
        resetCredentialsSubmit,
        validateSecurityQuestions,
        updateDetails,
        errorLogout,
        acceptTerms,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be defined within an AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
