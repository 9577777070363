import React from 'react';
import { theme, Box, Flex, TextStyled } from '../design-system';

const StepIndicator = ({ steps, currentStep, order, width }) => {
  const { navigationOrder } = currentStep;

  return (
    <Flex alignItems='center' justifyContent='space-between' order={order} width={width}>
      {steps.map((step, index) => {
        if (navigationOrder === index + 1) {
          return (
            <Flex key={`step${index + 1}Flex`} alignItems='center' mr={2}>
              <Box
                display='flex'
                height='29px'
                width='29px'
                justifyContent='center'
                alignItems='center'
                bg={theme.colors.white}
                border={`1px solid ${theme.colors.mediumGrey}`}
                borderColor={theme.colors.secondary}
                color={theme.colors.secondary}
                borderRadius='5px'
              >
                {index + 1}
              </Box>
              <TextStyled ml={2} as='span' fontSize='12px' color={theme.colors.secondary}>
                {step.stepDescription}
              </TextStyled>
            </Flex>
          );
        }
        if (navigationOrder > index + 1) {
          return (
            <Flex key={`step${index + 1}Flex`} alignItems='center' mr={2}>
              <Box
                display='flex'
                height='29px'
                width='29px'
                justifyContent='center'
                alignItems='center'
                bg={theme.colors.primary}
                border={1}
                borderColor={theme.colors.primary}
                color={theme.colors.white}
                borderRadius='5px'
              >
                {index + 1}
              </Box>
            </Flex>
          );
        }
        return (
          <Flex key={`step${index + 1}Flex`} alignItems='center' mr={2}>
            <Box
              display='flex'
              height='29px'
              width='29px'
              justifyContent='center'
              alignItems='center'
              bg={theme.colors.white}
              border={1}
              borderColor={theme.colors.mediumGrey}
              color={theme.colors.mediumGrey}
              borderRadius='5px'
            >
              {index + 1}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default StepIndicator;
