// external packages
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/fill';
import 'core-js/features/array/some';
import 'core-js/features/array/find-index';
import 'core-js/web';
import 'core-js/es/object';
import 'core-js/es/string';
import 'core-js/features/function';
import 'core-js/features/typed-array';

import browserUpdate from 'browser-update';

import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Amplify } from '@aws-amplify/core';
import { LocationProvider } from '@reach/router';

import {
  faEnvelope,
  faEnvelopeOpen,
  faCaretDown,
  faCaretUp,
  faCog,
  faFileAlt,
  faSignOutAlt,
  faPhone,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPrint,
  faInfoCircle,
  faUser,
  faBriefcase,
  faPiggyBank,
  faEye,
  faUserCircle,
  faFileSignature,
  faComments,
  faSearch,
  faPlus,
  faCircleXmark,
  faAngleDoubleRight,
  faCheck,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import './index.scss';
import AppProviders from './context';
import App from './app';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.NODE_ENV, release: process.env.REACT_APP_VERSION });

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    storage: window.sessionStorage,
  },
});

library.add(
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faSlack,
  faEnvelope,
  faEnvelopeOpen,
  faCaretDown,
  faCog,
  faSignOutAlt,
  faPhone,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPrint,
  faSearch,
  faInfoCircle,
  faUser,
  faBriefcase,
  faPiggyBank,
  faEye,
  faUserCircle,
  faFileSignature,
  faFileAlt,
  faComments,
  faCaretUp,
  faPlus,
  faCircleXmark,
  faAngleDoubleRight,
  faCheck,
  faClose,
);

browserUpdate({
  required: {
    e: -1,
    i: 12,
    f: -3,
    o: -3,
    s: -1,
    c: -3,
    samsung: 7.0,
    v: 1.2,
  },
  insecure: true,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppProviders>
    <LocationProvider>
      <App />
    </LocationProvider>
  </AppProviders>,
);
