/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Typography, type VariantPropsTypographyWithoutVariant, type TypographyVariantType } from './Typography';
import { FilterUnionType } from '../../lib/types/FilterUnionType';

type AllowedVariants = FilterUnionType<TypographyVariantType, 'p'> | FilterUnionType<TypographyVariantType, 'span'>;
type HTMLTypographyElement = HTMLParagraphElement;

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantPropsTypographyWithoutVariant {
  variant?: AllowedVariants;
  as?: AllowedVariants;
  size?: 'default' | 'sm' | 'xs';
}

const Text = React.forwardRef<HTMLTypographyElement, TextProps>(({ variant = 'p', as, ...props }, ref) => (
  <Typography ref={ref} variant={variant} as={as} {...props} />
));

Text.displayName = 'Text';

export { Text };
