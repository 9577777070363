import { Auth } from '@aws-amplify/auth';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getMobileNumber } from '../helpers/functions';
import captureDeviceData from '../helpers/captureDeviceData';
import client from './apiClient';
import {
  Accept_Registration_TermsDocument,
  Accept_Registration_TermsMutation,
  Accept_Registration_TermsMutationVariables,
  Communication_Preferences_MutationDocument,
  Communication_Preferences_MutationMutation,
  Communication_Preferences_MutationMutationVariables,
  Current_User_QueryDocument,
  Current_User_QueryQuery,
  Current_User_QueryQueryVariables,
  Pre_Auth_MutationDocument,
  Pre_Auth_MutationMutation,
  Pre_Auth_MutationMutationVariables,
  Set_Pin_MutationDocument,
  Set_Pin_MutationMutation,
  Set_Pin_MutationMutationVariables,
  Update_Details_MutationDocument,
  Update_Details_MutationMutation,
  Update_Details_MutationMutationVariables,
  UserAgreement,
  Validate_Details_MutationDocument,
  Validate_Details_MutationMutation,
  Validate_Details_MutationMutationVariables,
  Validate_Security_Questions_MutationDocument,
  Validate_Security_Questions_MutationMutation,
  Validate_Security_Questions_MutationMutationVariables,
} from '../graphql/types';
import { UserAttributes } from '../lib/security';

const getUser = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (!currentUser) {
      return null;
    }
    const { data, loading, error } = await client.query<Current_User_QueryQuery, Current_User_QueryQueryVariables>({
      query: Current_User_QueryDocument,
      fetchPolicy: 'network-only',
    });
    if (!loading && !error) {
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const login = async ({ email, password, pin }: { email: string; password: string; pin: string }) => {
  try {
    const newLogin = await Auth.signIn(email, password);
    const webDevice = await captureDeviceData();
    const {
      data: {
        preAuthCheck: { success },
      },
    } = await client.mutate<Pre_Auth_MutationMutation, Pre_Auth_MutationMutationVariables>({
      mutation: Pre_Auth_MutationDocument,
      variables: {
        pin,
        webDevice,
      },
    });
    if (success) {
      return newLogin;
    }
    return await Auth.signOut();
  } catch (err) {
    await Auth.signOut();
    throw err;
  }
};

const register = async ({
  memberNumber,
  email,
  password,
  pin,
  niNumber,
  dateOfBirth,
  mobileNumber,
  isRegistered,
}: {
  memberNumber: string;
  email: string;
  password: string;
  pin: string;
  niNumber: string;
  dateOfBirth: string;
  mobileNumber: string;
  isRegistered: boolean;
}) => {
  dayjs.extend(customParseFormat);
  const birthdate = dayjs(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const attributes: UserAttributes = {
    birthdate,
    'custom:memberNumber': memberNumber,
    'custom:niNumber': niNumber,
  };

  if (mobileNumber) {
    const phoneNumber = getMobileNumber(mobileNumber);
    attributes.phone_number = phoneNumber ? phoneNumber.number : '';
  }

  if (!isRegistered) {
    await Auth.signUp({
      username: email,
      password,
      attributes,
    });
  }

  await client.mutate<Set_Pin_MutationMutation, Set_Pin_MutationMutationVariables>({
    mutation: Set_Pin_MutationDocument,
    variables: {
      pin,
      email,
    },
  });
};

const validateDetails = async ({
  email,
  memberNumber,
  mobileNumber,
  dateOfBirth,
  niNumber,
}: {
  email: string;
  memberNumber: string;
  mobileNumber: string;
  dateOfBirth: string;
  niNumber: string;
}) => {
  const { data } = await client.mutate<Validate_Details_MutationMutation, Validate_Details_MutationMutationVariables>({
    mutation: Validate_Details_MutationDocument,
    variables: {
      email,
      memberNumber,
      mobileNumber,
      dateOfBirth,
      niNumber,
    },
  });
  return data;
};

const acceptTerms = async ({ memberNumber, userAgreements }: { memberNumber: string; userAgreements: UserAgreement[] }) => {
  const { data } = await client.mutate<Accept_Registration_TermsMutation, Accept_Registration_TermsMutationVariables>({
    mutation: Accept_Registration_TermsDocument,
    variables: { memberNumber, userAgreements },
  });

  return data;
};

const validateSecurityQuestions = async ({
  memberNumber,
  accountNumber,
  postCode,
  sortCode,
  regularContributions,
  regularContributionMethod,
}: {
  memberNumber: string;
  accountNumber?: string;
  postCode?: string;
  sortCode?: string;
  regularContributions?: number;
  regularContributionMethod?: string;
}) => {
  const { data } = await client.mutate<Validate_Security_Questions_MutationMutation, Validate_Security_Questions_MutationMutationVariables>({
    mutation: Validate_Security_Questions_MutationDocument,
    variables: {
      memberNumber,
      accountNumber,
      postCode,
      sortCode,
      regularContributions,
      regularContributionMethod,
    },
  });
  return data;
};

const updateDetails = async ({ email, password, mobileNumber }: { email: string; password: string; mobileNumber?: string }) => {
  try {
    await Auth.signIn(email, password);
    const { data } = await client.mutate<Update_Details_MutationMutation, Update_Details_MutationMutationVariables>({
      mutation: Update_Details_MutationDocument,
      variables: {
        email,
        mobileNumber,
      },
    });
    await Auth.signOut();
    return data;
  } catch (er) {
    await Auth.signOut();
    throw er;
  }
};

const completeRegistration = async ({
  communicationPreferences,
  email,
  password,
}: {
  communicationPreferences: {
    electronicDocuments: boolean;
    notifications: boolean;
  };
  email: string;
  password: string;
}) => {
  try {
    await Auth.signIn(email, password);
    const webDevice = await captureDeviceData();
    await client.mutate<Communication_Preferences_MutationMutation, Communication_Preferences_MutationMutationVariables>({
      mutation: Communication_Preferences_MutationDocument,
      variables: {
        communicationPreferences,
        webDevice,
      },
    });
    await Auth.signOut();
  } catch (er) {
    await Auth.signOut();
    throw er;
  }
};

const resetCredentials = async ({ email }: { email: string }) => {
  await Auth.forgotPassword(email);
};

const resetCredentialsSubmit = async ({
  email,
  authCode,
  password,
  pin,
  updatePinOnly,
}: {
  email: string;
  authCode: string;
  password: string;
  pin: string;
  updatePinOnly: boolean;
}) => {
  if (!updatePinOnly) {
    await Auth.forgotPasswordSubmit(email, authCode, password);
  }
  if (pin) {
    const { data } = await client.mutate<Set_Pin_MutationMutation, Set_Pin_MutationMutationVariables>({
      mutation: Set_Pin_MutationDocument,
      variables: {
        pin,
        email,
      },
    });
    return data;
  }
  return true;
};

const logout = async () => {
  try {
    return await Auth.signOut();
  } catch (error) {
    return error;
  }
};
export {
  login,
  register,
  logout,
  getUser,
  completeRegistration,
  validateDetails,
  resetCredentials,
  resetCredentialsSubmit,
  validateSecurityQuestions,
  updateDetails,
  acceptTerms,
};
