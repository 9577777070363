import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { Controller, ControllerProps, FieldPath, FieldValues, FormProvider, FormProviderProps, SubmitHandler, useFormContext } from 'react-hook-form';

import { cn } from '../../lib/utils';
import { Heading2 } from './Heading2';
import { ButtonGroup, ButtonProps } from './Button';
import { Input2, InputProps } from './Input2';
import { Label } from './Label';
import { PasswordInput, PasswordInputProps } from './PasswordInput';
import { CodeInput, CodeInputProps } from './CodeInput';
import { Textarea, TextareaProps } from './TextArea';
import { Select2, SelectProps } from './Select2';

interface FormWrapper<T extends FieldValues> extends FormProviderProps<T> {
  onSubmit: SubmitHandler<T>;
  title?: string;
}

const Form = <T extends FieldValues>(props: FormWrapper<T>) => {
  const { children, handleSubmit, onSubmit, title } = props;
  const formProviderProps = props as FormProviderProps;
  return (
    <FormProvider {...formProviderProps}>
      <form className='flex flex-1 flex-col' onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <Heading2 variant='h2' className='mb-10'>
          {title}
        </Heading2>
        {children}
      </form>
    </FormProvider>
  );
};

interface FormContentProps {
  children: React.ReactNode;
  middleAlign?: boolean;
}

const FormContent = ({ children, middleAlign }: FormContentProps) => (
  <div className={cn('flex flex-1 flex-col space-y-12', middleAlign ? 'justify-center' : '')}>{children}</div>
);

interface FormFieldsProps {
  children: React.ReactNode;
  width?: '3/4' | 'full';
}

const FormFields = ({ children, width = '3/4' }: FormFieldsProps) => (
  <div className={`${width === '3/4' ? 'lg:w-7/12' : 'w-full'} space-y-12`}>{children}</div>
);

interface FormButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  primary: React.ReactElement<ButtonProps>;
  secondary?: React.ReactElement<ButtonProps>;
}

const FormButtonGroup = React.forwardRef<HTMLDivElement, FormButtonGroupProps>(({ className, ...props }, ref) => {
  const { formState } = useFormContext();

  const primary = React.cloneElement(
    props.primary,
    props.primary.props.variant && props.primary.props.variant !== 'default'
      ? { disabled: formState.isSubmitting }
      : { disabled: formState.isSubmitting, variant: 'default' },
  );
  const secondary = props.secondary
    ? React.cloneElement(
        props.secondary,
        props.secondary.props.variant && props.primary.props.variant !== 'secondary'
          ? { disabled: formState.isSubmitting }
          : { disabled: formState.isSubmitting, variant: 'secondary' },
      )
    : null;

  return <ButtonGroup className={className} primary={primary} secondary={secondary} ref={ref} />;
});
FormButtonGroup.displayName = 'FormButtonGroup';

interface FormFieldContextValue<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TName;
}

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  ...props
}: ControllerProps<TFieldValues, TName>) => (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  <FormFieldContext.Provider value={{ name: props.name }}>
    <div className='w-full'>
      <Controller {...props} />
    </div>
  </FormFieldContext.Provider>
);

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

interface FormItemContextValue {
  id: string;
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('mb-8', className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(
  ({ className, ...props }, ref) => {
    const { error, formItemId } = useFormField();

    return (
      <Label
        ref={ref}
        className={cn('text-sm block mb-4 font-bold text-fields', error && 'text-destructive', className)}
        htmlFor={formItemId}
        {...props}
      />
    );
  },
);
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return <p ref={ref} id={formDescriptionId} className={cn('text-sm text-fields', className)} {...props} />;
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p ref={ref} id={formMessageId} className={cn('text-sm font-medium text-destructive', className)} {...props}>
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, ref) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl>
        <Input2 {...props} ref={ref} error={error?.message} />
      </FormControl>
    </FormItem>
  );
});

FormInput.displayName = 'FormInput';

const FormTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ ...props }, ref) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl>
        <Textarea {...props} ref={ref} error={error?.message} />
      </FormControl>
    </FormItem>
  );
});

FormTextarea.displayName = 'FormTextarea';

const FormPasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(({ ...props }, ref) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl>
        <PasswordInput {...props} ref={ref} error={error?.message} />
      </FormControl>
    </FormItem>
  );
});

FormPasswordInput.displayName = 'FormPasswordInput';

const FormCodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(({ ...props }, ref) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl>
        <CodeInput {...props} ref={ref} error={error?.message} />
      </FormControl>
    </FormItem>
  );
});

FormCodeInput.displayName = 'FormCodeInput';

const FormSelect = ({ ...props }: SelectProps) => {
  const { error } = useFormField();

  return (
    <FormItem>
      <FormControl>
        <Select2 {...props} error={error?.message} />
      </FormControl>
    </FormItem>
  );
};

FormSelect.displayName = 'FormSelect';

export {
  useFormField,
  Form,
  FormContent,
  FormFields,
  FormButtonGroup,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormInput,
  FormTextarea,
  FormPasswordInput,
  FormCodeInput,
  FormSelect,
};
