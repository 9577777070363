import React, { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../lib/utils';
import { Heading2 } from './Heading2';
import { Text } from './Text';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  content?: string;
  action?:
    | {
        href: string;
        target?: React.HTMLAttributeAnchorTarget;
      }
    | (() => void);
  leftView?: ReactNode;
}

const ListItem = ({ className, title, content, action, leftView, ...props }: Props) => {
  const item = (
    <div className={cn('flex border-solid border-0 border-b-2 border-listItemSeparator mb-5', className)} {...props}>
      <div className='flex flex-1'>
        {leftView && <div className='flex items-center mx-10'>{leftView}</div>}
        <div className='flex flex-col justify-center mr-5 my-5'>
          <Heading2 variant='h3'>{title}</Heading2>
          {content && <Text className='m-0 text-subText'>{content}</Text>}
        </div>
      </div>
      <div className='flex items-center'>
        <FontAwesomeIcon icon='angle-double-right' className='size-8 text-fields' />
      </div>
    </div>
  );

  if (typeof action === 'object') {
    return (
      <a href={action.href} target={action.target} rel='noreferrer' className='no-underline'>
        {item}
      </a>
    );
  }
  if (typeof action === 'function') {
    return (
      <div role='button' tabIndex={0} onClick={action} onKeyDown={action} className='cursor-grab'>
        {item}
      </div>
    );
  }

  return item;
};
ListItem.displayName = 'ListItem';

// eslint-disable-next-line import/prefer-default-export
export { ListItem };
