import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';

import dayjs from 'dayjs';
import { cn } from '../../lib/utils';
import { Label } from './Label';

const INPUT_PATTERN_NUMBER = '[0-9]*';
const INPUT_PATTERN_CALENDAR = '(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-\\d{4}';

const INPUT_OPTIONS_SORT_CODE: CleaveOptions = {
  delimiters: ['-'],
  blocks: [2, 2, 2],
};

const INPUT_PLACEHOLDER_SORT_CODE = '12-34-56';

const INPUT_OPTIONS_CURRENCY: CleaveOptions = {
  numeral: true,
  prefix: '£ ',
  rawValueTrimPrefix: true,
  numeralThousandsGroupStyle: 'thousand',
};

const INPUT_OPTIONS_NI_NUMBER: CleaveOptions = {
  delimiters: ['-'],
  blocks: [2, 2, 2, 2, 1],
  uppercase: true,
};

const INPUT_PLACEHOLDER_CALENDAR = 'DD-MM-YYYY';
const INPUT_PLACEHOLDER_NI_NUMBER = 'AB-12-34-56-A';

const INPUT_OPTIONS_CALENDAR: CleaveOptions = {
  date: true,
  dateMin: '1900-01-01',
  dateMax: dayjs().format('YYYY-MM-DD'),
  delimiters: ['-'],
  datePattern: ['d', 'm', 'Y'],
};

const INPUT_MEMBER_NUMBER_LENGTH = 8;
const INPUT_NI_NUMBER_LENGTH = 9;
const INPUT_EMAIL_LENGTH = 127;
const INPUT_PASSWORD_LENGTH = 127;
const INPUT_MOBILE_NUMBER_LENGTH = 13;

export interface ChangeEvent<T> extends React.FocusEvent<T> {
  target: { rawValue: string } & EventTarget & T;
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  error?: string;
  options?: CleaveOptions;
  label?: string;
  helperText?: string;
  inputStyle?: string;
}

const Input2 = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, id, type, icon, label, error, options, helperText, inputStyle, ...props }, ref) => {
    const base = 'flex box-border py-6 px-4 h-20 bg-transparent rounded-md focus:outline-none border border-fields border-solid text-base w-full';

    const errorStyle = error ? 'border-destructive focus:border-destructive' : 'focus:border-primary';
    const cnClassName = cn(base, className, inputStyle, errorStyle);

    return (
      <div className={className}>
        {label && (
          <Label className={cn('block mb-4 font-bold text-fields', error && 'text-destructive')} htmlFor={id}>
            {label}
          </Label>
        )}
        <div className={cn('flex flex-row justify-between w-full relative')}>
          {options ? (
            <Cleave {...props} className={cnClassName} options={options} />
          ) : (
            <input type={type} className={cnClassName} ref={ref} {...props} />
          )}
          <div className='flex items-center justify-center absolute right-4 h-full'>{icon}</div>
        </div>
        {helperText && <p className={cn('text-sm text-fields')}>{helperText}</p>}
        {error && <p className={cn('text-sm font-medium text-destructive')}>{error}</p>}
      </div>
    );
  },
);

Input2.displayName = 'Input2';

export {
  Input2,
  INPUT_OPTIONS_SORT_CODE,
  INPUT_PLACEHOLDER_SORT_CODE,
  INPUT_OPTIONS_CURRENCY,
  INPUT_PATTERN_NUMBER,
  INPUT_OPTIONS_NI_NUMBER,
  INPUT_OPTIONS_CALENDAR,
  INPUT_PATTERN_CALENDAR,
  INPUT_PLACEHOLDER_CALENDAR,
  INPUT_EMAIL_LENGTH,
  INPUT_PASSWORD_LENGTH,
  INPUT_MOBILE_NUMBER_LENGTH,
  INPUT_NI_NUMBER_LENGTH,
  INPUT_MEMBER_NUMBER_LENGTH,
  INPUT_PLACEHOLDER_NI_NUMBER,
};
