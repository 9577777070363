import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import zxcvbn, { ZXCVBNScore } from 'zxcvbn';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { cn } from '../../lib/utils';
import { ChangeEvent, INPUT_PASSWORD_LENGTH, Input2, InputProps } from './Input2';
import { Text } from './Text';
import { ProgressBar } from './ProgressBar';

type Rule = 'symbols' | 'words' | 'digits' | 'letters' | 'lowerCase' | 'upperCase';

const strengthLevels = [
  {
    label: 'Weak',
  },
  {
    label: 'Not quite',
  },
  {
    label: 'Nearly there',
  },
  {
    label: 'Good',
  },
  {
    label: 'Strong',
  },
];

const tooShort = {
  labelColor: '#fe6c6c',
  label: 'Too short',
  innerBarColor: '#fe6c6c',
};

library.add(faEye, faEyeSlash);

export interface PasswordInputProps extends InputProps {
  showStrengthChecker?: boolean;
  minStrengthCheckerLevel?: ZXCVBNScore;
  minLength?: number;
  ruleNames?: Rule[];
  tooShortEnabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChangePassword: (password: string, feedback?: zxcvbn.ZXCVBNFeedback | {}, isValid?: boolean) => void;
}

const regex = {
  digitsPattern: /\d/,
  lettersPattern: /[a-zA-Z]/,
  lowerCasePattern: /[a-z]/,
  upperCasePattern: /[A-Z]/,
  wordsPattern: /\w/,
  symbolsPattern: /\W/,
};

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    { className, showStrengthChecker, minLength, onChangePassword, error, ruleNames, tooShortEnabled, minStrengthCheckerLevel = 3, ...props },
    ref,
  ) => {
    const [strengthLevel, setStrengthLevel] = useState(-1);
    const [isTooShort, setIsTooShort] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [strengthText, setStrengthText] = useState('');

    useEffect(() => {
      if (strengthLevel !== -1) {
        if (tooShortEnabled && isTooShort) {
          setStrengthText(tooShort.label);
        } else {
          // eslint-disable-next-line security/detect-object-injection
          setStrengthText(strengthLevels[strengthLevel].label);
        }
      } else {
        setStrengthText('');
      }
    }, [isTooShort, tooShortEnabled, strengthLevel]);

    const isPasswordTooShort = (password: string) => {
      if (!minLength) {
        return false;
      }
      return password.length < minLength;
    };

    const isMatchingRule = (password: string, rule: Rule) => {
      switch (rule) {
        case 'symbols':
          return regex.symbolsPattern.test(password);
        case 'words':
          return regex.wordsPattern.test(password);
        case 'digits':
          return regex.digitsPattern.test(password);
        case 'letters':
          return regex.lettersPattern.test(password);
        case 'lowerCase':
          return regex.lowerCasePattern.test(password);
        case 'upperCase':
          return regex.upperCasePattern.test(password);
        default:
          return true;
      }
    };

    const isMatchingRules = (password: string) => {
      if (!ruleNames) {
        return true;
      }

      const hasViolatedRule = ruleNames.some(rule => !isMatchingRule(password, rule));
      if (hasViolatedRule) return false;
      return true;
    };

    const calculateScore = (scoreText: string) => {
      if (!scoreText) {
        setIsTooShort(false);
        return { score: -1, feedback: {} };
      }

      if (isPasswordTooShort(scoreText)) {
        setIsTooShort(true);
        return { score: 0, feedback: {} };
      }

      setIsTooShort(false);

      if (!isMatchingRules(scoreText)) {
        return { score: 0, feedback: {} };
      }

      return zxcvbn(scoreText);
    };

    const onChangeTextPassword = (password: string) => {
      if (showStrengthChecker) {
        const { score, feedback } = calculateScore(password);
        setStrengthLevel(score);
        const isValid = isMatchingRules(password) && score >= minStrengthCheckerLevel;
        onChangePassword(password, feedback, isValid);
      } else {
        onChangePassword(password);
      }
    };

    const strengthColour = strengthLevel >= minStrengthCheckerLevel ? 'bg-primary' : 'bg-destructive';

    const onToggle = () => {
      setPasswordVisible(s => !s);
    };
    const errorStyle = error ? 'border-destructive' : '';
    const inputStyle = !passwordVisible ? cn('text-primary font-mask text-xl tracking-[9px]', errorStyle) : 'text-base';
    const cnClassName = cn(className, 'w-full');
    return (
      <>
        <Input2
          type={passwordVisible ? 'text' : 'password'}
          className={cnClassName}
          label='Password'
          inputStyle={inputStyle}
          ref={ref}
          {...props}
          maxLength={INPUT_PASSWORD_LENGTH}
          icon={
            passwordVisible ? (
              <FontAwesomeIcon icon='eye-slash' onClick={onToggle} className='text-fields text-sm cursor-pointer' />
            ) : (
              <FontAwesomeIcon icon='eye' onClick={onToggle} className='text-fields text-sm cursor-pointer' />
            )
          }
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            onChangeTextPassword(e.currentTarget.value);
          }}
        />
        {!!strengthText && (
          <>
            <Text>
              Password Strength: <Text as='span' className='font-bold text-foreground'>{`${strengthText.toUpperCase()}`}</Text>
            </Text>
            <ProgressBar currentStep={strengthLevel + 1} steps={strengthLevels.length} showLabel={false} progressColour={strengthColour} />
          </>
        )}
      </>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };
